.table{
  overflow: auto;
  table-layout: auto !important;
}


.rowNoMarginX{
  margin-left: 0px;
  margin-right: 0px;
}

.marginBottomCustom{
  margin-bottom: 8px;
}

.marginBottomCards{
  margin-bottom: 7px!important;
}

.marginTopCards{
  margin-top: 7px!important;
}


i.menuIcon:hover{
  opacity:0.3;
}
